<template>
  <div v-cloak>
    <div class="wrap">

          <router-link class="back-link router-link-active" to="/config">
            <svg data-v-231527a2="" fill="none" height="12" viewBox="0 0 8 12" width="8"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M7.41 10.58L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.58Z" data-v-231527a2="" fill="#0033A0"></path>
            </svg>
            назад
          </router-link>


      <h1>Настройка чек-листов подсистемы<br/>«Индекс клиентоцентричности»</h1>

      <div class="list">
        <table>
          <thead>
            <tr>
              <th>Наименование</th>
              <th>Дата создания</th>
              <th>Дата изменения</th>
              <th>Статус</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Чек-листы 2022</td>
              <td>15.11.2022</td>
              <td>15.11.2022</td>
              <td>
                <span>Не действующая</span><br/>
                <a href="#">Сделать действующей</a>
              </td>
              <td>
                <div class="edit">
                  <router-link  to="">
                    <v-icon class="icon-item icon-briefcase-eye-outline"></v-icon>
                  </router-link>
                </div>
                <div class="edit">
                  <router-link  to="indexClientEdit">
                    <v-icon class="icon-item icon-note-edit-outline"></v-icon>
                  </router-link>
                </div>

              </td>
            </tr>
             <tr>
              <td>Чек-листы 2023</td>
              <td>15.11.2022</td>
              <td>15.11.2022</td>
              <td>
                <span style="color: darkgreen;">Действующая</span>
              </td>
              <td>
                <div class="edit">
                  <router-link to="">
                    <v-icon class="icon-item icon-briefcase-eye-outline"></v-icon>
                  </router-link>
                </div>
                <div class="edit">
                  <router-link  to="indexClientEdit">
                    <v-icon class="icon-item icon-note-edit-outline"></v-icon>
                  </router-link>
                </div>

              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
      <v-btn>Создать конфигурацию</v-btn>


    </div>
  </div>
</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style scoped lang="scss">
table {
  margin-bottom: 50px;
  tr:last-child td, th {
    border-bottom: 1px solid #E8E8E9 !important;
  }
}
</style>

<script>

export default {
  name: "indexClient",
  props: {
  },
  components: {},
  data() {
    return {
    };
  }
};
</script>
